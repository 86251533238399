'use client'

import { useTranslations } from 'next-intl'

import { Box, ButtonPill, ContentText, fontSizes, spacing } from '@fortum/elemental-ui'

import { Link } from '@/shared/navigation'
import { lightColors } from '@/shared/utils/colors'

import { Section } from '../Section'
import { StyledHeading } from '../StyledHeading'

export type RedirectPageProps = { redirectUrl: string }

export const RedirectPage = ({ redirectUrl }: RedirectPageProps) => {
  const t = useTranslations('redirectPage')

  return (
    <Section
      justifyContent="center"
      backgroundColor={lightColors.backgroundSecondary}
      pt={spacing.m}
    >
      <Box mb={spacing.xl} textAlign="center">
        <StyledHeading level={1} styledAs={2} mb={spacing.xs}>
          {t('title')}
        </StyledHeading>
        <ContentText fontSize={fontSizes.l}>
          {t('description')} ({redirectUrl})
        </ContentText>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flexDirection={{ default: 'column', s: 'row' }}
        gap={{ default: spacing.xxs, m: spacing.m }}
        mb={spacing.xxl}
      >
        <ButtonPill tag={Link} href={redirectUrl} status="secondary" whiteSpace="nowrap">
          {t('goToSite')}
        </ButtonPill>

        <ButtonPill tag={Link} href="/" whiteSpace="nowrap">
          {t('backToFortum')}
        </ButtonPill>
      </Box>
    </Section>
  )
}
